<!--
 * @Descripttion: 资源文件选择器
 * @version: 1.0
 * @Author: sakuya
 * @Date: 2021年10月11日16:01:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025-01-23 09:59:13
-->

<template>
	<div class="sc-file-select">
		<div class="sc-file-select__side" v-loading="menuLoading">
			<div class="sc-file-select__side-add">
				<el-button type="primary" icon="el-icon-plus" @click="addGroup">新增分类</el-button>
			</div>
			<div class="sc-file-select__side-search">
				<el-input v-model="query" placeholder="请输入分类名称" clearable @input="onQueryChanged" />
			</div>
			<div class="sc-file-select__side-menu">
				<el-tree-v2 ref="group" class="menu" :data="menu" :node-key="treeProps.key" :props="treeProps"
					:current-node-key="menuId" @node-click="groupClick" :item-size="36" :height="427"
					:expand-on-click-node="false" :filter-method="filterMethod">
					<template #default="{ node }">
						<span class="custom-tree-node">
							<span class="el-tree-node__label"
								:class="{ 'el-tree-node__label_active': menuId === node.key }">
								{{ node.label }}
							</span>
							<span class="btnGroup" @click.stop v-if="node.key !== 1">
								<el-button size="small" icon="el-icon-edit" circle @click.stop="editGroup(node.data)" />
								<el-button size="small" type="danger" icon="el-icon-delete" circle
									@click.stop="delGroup(node.data)" />
							</span>
						</span>
					</template>
				</el-tree-v2>
			</div>
			<div class="sc-file-select__side-msg" v-if="multiple">
				已选择 <b>{{ value.length }}</b> / <b>{{ max }}</b> 项
			</div>
		</div>
		<div class="sc-file-select__files" v-loading="listLoading">
			<div class="sc-file-select__top">
				<div class="upload" v-if="!hideUpload">
					<el-upload class="sc-file-select__upload" action="" multiple :show-file-list="false" :accept="accept"
						:on-change="uploadChange" :before-upload="uploadBefore" :on-progress="uploadProcess"
						:on-success="uploadSuccess" :on-error="uploadError" :http-request="uploadRequest">
						<el-button type="primary" icon="el-icon-upload">本地上传</el-button>
					</el-upload>
					<div class="sc-file-select__upload">
						<el-button type="danger" icon="el-icon-delete" :disabled="value.length <= 0"
							@click="deleteFile">删除图片</el-button>
					</div>
					<span class="tips"><el-icon><el-icon-warning /></el-icon>大小不超过{{ maxSize }}MB</span>
				</div>
				<div class="keyword">
					<!-- <el-input v-model="keyword" prefix-icon="el-icon-search" placeholder="文件名搜索" clearable
						@keyup.enter="search" @clear="search"></el-input> -->
				</div>
			</div>
			<div class="sc-file-select__list">
				<el-scrollbar ref="scrollbar">
					<el-empty v-if="fileList.length == 0 && data.length == 0" description="无数据" :image-size="80"></el-empty>
					<div v-for="(file, index) in fileList" :key="index" class="sc-file-select__item">
						<div class="sc-file-select__item__file">
							<div class="sc-file-select__item__upload">
								<el-progress type="circle" :percentage="file.progress" :width="70"></el-progress>
							</div>
							<el-image :src="file.tempImg" fit="cover" loading="lazy"></el-image>
						</div>
						<!-- <p>{{ file.name }}</p> -->
					</div>
					<div v-for="item in data" :key="item[fileProps.key]" class="sc-file-select__item"
						:class="{ active: value.includes(item[fileProps.url]) }" @click="select(item)">
						<div class="sc-file-select__item__file">
							<div class="sc-file-select__item__checkbox" v-if="multiple">
								<el-icon><el-icon-check /></el-icon>
							</div>
							<div class="sc-file-select__item__select" v-else>
								<el-icon><el-icon-check /></el-icon>
							</div>
							<div class="sc-file-select__item__box"></div>
							<el-image v-if="_isImg(item[fileProps.url])" :src="item[fileProps.url]" fit="cover"
								loading="lazy" />
							<div v-else class="item-file item-file-doc">
								<i v-if="files[_getExt(item[fileProps.url])]"
									:class="files[_getExt(item[fileProps.url])].icon"
									:style="{ color: files[_getExt(item[fileProps.url])].color }"></i>
								<i v-else class="sc-icon-file-list-fill" style="color: #999;"></i>
							</div>
						</div>
						<!-- <p :title="item[fileProps.fileName]">{{ item[fileProps.fileName] }}</p> -->
					</div>
				</el-scrollbar>
			</div>
			<div class="sc-file-select__pagination">
				<el-pagination size="small" background layout="prev, pager, next" :total="total" :page-size="pageSize"
					v-model:currentPage="currentPage" @current-change="reload"></el-pagination>
			</div>
			<div class="sc-file-select__do">
				<slot name="do"></slot>
				<el-button type="primary" :disabled="value.length <= 0" @click="submit">确 定</el-button>
			</div>
		</div>
	</div>
	<group-dialog v-if="dialog.save" ref="groupDialog" @success="handleSuccess" @closed="dialog.save = false" />
</template>

<script>
import config from "@/config/fileSelect"
import groupDialog from "./group"
export default {
	emits: ['update:modelValue', 'submit'],
	props: {
		modelValue: null,
		hideUpload: { type: Boolean, default: false },
		multiple: { type: Boolean, default: false },
		max: { type: Number, default: config.max },
		onlyImage: { type: Boolean, default: true },
		maxSize: { type: Number, default: config.maxSize },
	},
	components: {
		groupDialog
	},
	data() {
		return {
			dialog: {
				save: false
			},
			keyword: null,
			pageSize: 18,
			total: 0,
			currentPage: 1,
			data: [],
			menu: [],
			menuId: '',
			value: this.multiple ? [] : '',
			selection: this.multiple ? [] : '',
			fileList: [],
			accept: this.onlyImage ? "image/gif, image/jpeg, image/png, image/webp" : "",
			listLoading: false,
			menuLoading: false,
			treeProps: config.menuProps,
			fileProps: config.fileProps,
			files: config.files,
			query: ''
		}
	},
	watch: {
		multiple() {
			this.value = this.multiple ? [] : ''
			this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
		}
	},
	mounted() {
		this.getMenu()
	},
	methods: {
		onQueryChanged(query) {
			this.$refs.group.filter(query)
		},
		filterMethod(query, node) {
			return node.group_name.includes(query)
		},
		//获取分类数据
		async getMenu() {
			this.menuLoading = true
			var res = await config.menuApiObj.post()
			this.menu = res.data
			if (res.data.length > 0) {
				this.menuId = res.data[0][config.menuProps.key]
				this.$refs.group.setCheckedKeys([res.data[0][config.menuProps.key]])
			}
			this.getData()
			this.menuLoading = false
		},
		//获取列表数据
		async getData() {
			this.listLoading = true
			var reqData = {
				[config.request.groupKey]: this.menuId,
				[config.request.page]: this.currentPage,
				[config.request.pageSize]: this.pageSize,
				[config.request.keyword]: this.keyword
			}
			if (this.onlyImage) {
				reqData.type = 'image'
			}
			var res = await config.listApiObj.post(reqData)
			var parseData = config.listParseData(res)
			this.data = parseData.rows
			this.total = parseData.total
			this.listLoading = false
			this.$nextTick(() => {
				this.$refs.scrollbar.setScrollTop(0)
			})
		},
		//树点击事件
		groupClick(data) {
			this.menuId = data[config.menuProps.key]
			this.currentPage = 1
			this.keyword = null
			this.getData()
		},
		//分页刷新表格
		reload() {
			this.getData()
		},
		search() {
			this.currentPage = 1
			this.getData()
		},
		select(item) {
			const itemUrl = item[this.fileProps.url]
			const key = item[this.fileProps.key]
			if (this.multiple) {
				if (this.value.includes(itemUrl)) {
					this.value.splice(this.value.findIndex(f => f == itemUrl), 1)
					this.selection.filter(item => item !== key)
				} else {
					if (this.value.length === this.max) return
					this.value.push(itemUrl)
					this.selection.push(key)
				}
			} else {
				if (this.value.includes(itemUrl)) {
					this.value = ''
					this.selection = ''
				} else {
					this.value = itemUrl
					this.selection = key
				}
			}
		},
		async deleteFile() {
			this.$msgbox({
				title: "删除选中图片",
				message: "确定要删除选中图片吗？\n删除选中图片后将无法恢复，请谨慎操作！",
				center: true,
				showClose: false,
				showCancelButton: true
			}).then(async () => {
				let query = {
					ids: this.multiple ? this.selection : [this.selection]
				}
				var res = await config.listDeleteObj.post(query)
				if (res?.code === 1) {
					this.$notify.success({
						title: '删除成功'
					})
					this.value = this.multiple ? [] : ''
					this.selection = this.multiple ? [] : ''
					this.getData()
				}
			}).catch(() => {
				//
			})
		},
		submit() {
			const value = JSON.parse(JSON.stringify(this.value))
			this.$emit('update:modelValue', value);
			this.$emit('submit', value);
		},
		//上传处理
		uploadChange(file, fileList) {
			file.tempImg = URL.createObjectURL(file.raw);
			this.fileList = fileList
		},
		uploadBefore(file) {
			const maxSize = file.size / 1024 / 1024 < this.maxSize;
			if (!maxSize) {
				this.$message.warning(`上传文件大小不能超过 ${this.maxSize}MB!`);
				return false;
			}
		},
		uploadRequest(param) {
			var apiObj = config.apiObj;
			const data = new FormData();
			data.append("file", param.file);
			data.append("type", "image");
			data.append("file_name", '新增素材');
			data.append([config.fileProps.groupKey], this.menuId);
			// data.append([config.request.menuKey], this.$TOOL.data.get("USER_INFO").id);
			apiObj.post(data, {
				onUploadProgress: e => {
					param.onProgress(e)
				}
			}).then(res => {
				param.onSuccess(res)
			}).catch(err => {
				param.onError(err)
			})
		},
		uploadProcess(event, file) {
			file.progress = Number((event.loaded / event.total * 100).toFixed(2))
		},
		uploadSuccess(res, file) {
			this.getData()
			this.fileList.splice(this.fileList.findIndex(f => f.uid == file.uid), 1)
			this.$notify.success({
				title: '上传成功'
			})
		},
		uploadError(err) {
			this.$notify.error({
				title: '上传文件错误',
				message: err
			})
		},
		//内置函数
		_isImg(fileUrl) {
			const imgExt = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp']
			const fileExt = fileUrl.substring(fileUrl.lastIndexOf(".")).toLocaleLowerCase()
			return imgExt.indexOf(fileExt) != -1
		},
		_getExt(fileUrl) {
			return fileUrl.substring(fileUrl.lastIndexOf(".") + 1)
		},
		addGroup() {
			this.dialog.save = true
			this.$nextTick(() => {
				this.$refs.groupDialog.open('add')
			})
		},
		editGroup(data) {
			this.dialog.save = true
			this.$nextTick(() => {
				this.$refs.groupDialog.open('edit', data)
			})
		},
		delGroup(data) {
			this.$confirm(`确定当前分类吗？`, '提示', {
				type: 'warning'
			}).then(async () => {
				let res = await this.$API.common.file.group.delete.post({
					id: data[config.menuProps.key]
				})
				if (res?.code === 1) {
					this.$message.success("操作成功")
					this.getMenu()
				}
			}).catch(() => { })
		},
		handleSuccess() {
			this.getMenu()
		}
	}
}
</script>
<style lang="scss" scoped>
.sc-file-select {
	display: flex;
}

.sc-file-select__files {
	flex: 1;
}

.sc-file-select__list {
	height: 400px;
}

.sc-file-select__item {
	display: inline-block;
	float: left;
	margin: 0 15px 25px 0;
	width: 110px;
	cursor: pointer;
}

.sc-file-select__item__file {
	width: 110px;
	height: 110px;
	position: relative;
}

.sc-file-select__item__file .el-image {
	width: 110px;
	height: 110px;
}

.sc-file-select__item__box {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 2px solid var(--el-color-success);
	z-index: 1;
	display: none;
}

.sc-file-select__item__box::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--el-color-success);
	opacity: 0.2;
	display: none;
}

.sc-file-select__item:hover .sc-file-select__item__box {
	display: block;
}

.sc-file-select__item.active .sc-file-select__item__box {
	display: block;
}

.sc-file-select__item.active .sc-file-select__item__box::before {
	display: block;
}

.sc-file-select__item p {
	margin-top: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-text-overflow: ellipsis;
	text-align: center;
}

.sc-file-select__item__checkbox {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 7px;
	right: 7px;
	z-index: 2;
	background: rgba(0, 0, 0, 0.2);
	border: 1px solid #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.sc-file-select__item__checkbox i {
	font-size: 14px;
	color: #fff;
	font-weight: bold;
	display: none;
}

.sc-file-select__item__select {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0px;
	right: 0px;
	z-index: 2;
	background: var(--el-color-success);
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.sc-file-select__item__select i {
	font-size: 14px;
	color: #fff;
	font-weight: bold;
}

.sc-file-select__item.active .sc-file-select__item__checkbox {
	background: var(--el-color-success);
}

.sc-file-select__item.active .sc-file-select__item__checkbox i {
	display: block;
}

.sc-file-select__item.active .sc-file-select__item__select {
	display: flex;
}

.sc-file-select__item__file .item-file {
	width: 110px;
	height: 110px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.sc-file-select__item__file .item-file i {
	font-size: 40px;
}

.sc-file-select__item__file .item-file.item-file-doc {
	color: #409eff;
}

.sc-file-select__item__upload {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.sc-file-select__side {
	width: 300px;
	margin-right: 15px;
	border-right: 1px solid rgba(128, 128, 128, 0.2);
	display: flex;
	flex-flow: column;
}

.sc-file-select__side-add {
	flex-shrink: 0;
	margin-bottom: 5px;
}

.sc-file-select__side-search {
	flex-shrink: 0;
	padding-right: 15px;
	margin-bottom: 5px;
}

.sc-file-select__side-menu {
	flex: 1;
	overflow-y: auto;
	margin-right: 8px;

	.menu {
		height: 100%;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 14px;

		&:hover {
			.btnGroup {
				display: block;
			}
		}

		.el-tree-node__label_active {
			color: var(--el-color-primary);
		}

		.btnGroup {
			display: none;
		}
	}
}

.sc-file-select__side-msg {
	height: 32px;
	line-height: 32px;
}

.sc-file-select__top {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}

.sc-file-select__top .upload {
	display: flex;
	align-items: center;
}

.sc-file-select__top .upload>*+* {
	margin-left: 10px;
}

.sc-file-select__upload {
	display: flex;
	align-items: center;
}

.sc-file-select__top .tips {
	font-size: 12px;
	margin-left: 10px;
	color: #999;
}

.sc-file-select__top .tips i {
	font-size: 14px;
	margin-right: 5px;
	position: relative;
	bottom: -0.125em;
}

.sc-file-select__pagination {
	margin: 15px 0;
}

.sc-file-select__do {
	text-align: right;
}
</style>
