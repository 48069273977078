import config from "./config";
import api from "./api";
import tool from "./utils/tool";
import http from "./utils/request";
import { loadJS } from "./utils/load";
import { permission, rolePermission } from "./utils/permission";

import scTable from "./components/scTable";
import scTableColumn from "./components/scTable/column.js";
import scFilterBar from "./components/scFilterBar";
import scUpload from "./components/scUpload";
import scUploadMultiple from "./components/scUpload/multiple";
import scUploadFile from "./components/scUpload/file";
import scFormTable from "./components/scFormTable";
import scTableSelect from "./components/scTableSelect";
import scPageHeader from "./components/scPageHeader";
import scSelect from "./components/scSelect";
import scDialog from "./components/scDialog";
import scForm from "./components/scForm";
import scTitle from "./components/scTitle";
import scWaterMark from "./components/scWaterMark";
import scQrCode from "./components/scQrCode";

//! 引入自定义图片上传组件
import scImageSelect from "./components/scImageSelect";
import scImageUpload from "./components/scImageUpload";
//! 引入自定义视频上传组件
import scVideoUpload from "./components/scVideoUpload";

//! 引入路径选择器组件
import scUrlSelect from "./components/scUrlSelect";
//! 引入商品选择器组件
import scGoodSelect from "./components/scGoodSelect";

import scStatusIndicator from "./components/scMini/scStatusIndicator";
import scTrend from "./components/scMini/scTrend";

import auth from "./directives/auth";
import auths from "./directives/auths";
import authsAll from "./directives/authsAll";
import role from "./directives/role";
import time from "./directives/time";
import copy from "./directives/copy";
import has from "./directives/btnPermissions";
import errorHandler from "./utils/errorHandler";

import * as elIcons from "@element-plus/icons-vue";
import * as scIcons from "./assets/icons";
import * as i4Icons from "@vicons/ionicons4";
import * as i5Icons from "@vicons/ionicons5";

export default {
	install(app) {
		loadJS(
			"https://map.qq.com/api/gljs?v=1.exp&key=QN5BZ-3DVCQ-RV55K-2JX4K-HOT3O-CXBW3",
			"TMap"
		);
		loadJS(
			"https://map.qq.com/api/gljs?v=1.exp&key=QN5BZ-3DVCQ-RV55K-2JX4K-HOT3O-CXBW3&libraries=service",
			"TMapService"
		);
		//挂载全局对象
		app.config.globalProperties.$CONFIG = config;
		app.config.globalProperties.$TOOL = tool;
		app.config.globalProperties.$HTTP = http;
		app.config.globalProperties.$API = api;
		app.config.globalProperties.$AUTH = permission;
		app.config.globalProperties.$ROLE = rolePermission;

		//注册全局组件
		app.component("scTable", scTable);
		app.component("scTableColumn", scTableColumn);
		app.component("scFilterBar", scFilterBar);
		app.component("scUpload", scUpload);
		app.component("scUploadMultiple", scUploadMultiple);
		app.component("scUploadFile", scUploadFile);
		app.component("scFormTable", scFormTable);
		app.component("scTableSelect", scTableSelect);
		app.component("scPageHeader", scPageHeader);
		app.component("scSelect", scSelect);
		app.component("scDialog", scDialog);
		app.component("scForm", scForm);
		app.component("scTitle", scTitle);
		app.component("scWaterMark", scWaterMark);
		app.component("scQrCode", scQrCode);
		app.component("scStatusIndicator", scStatusIndicator);
		app.component("scTrend", scTrend);
		app.component("scImageSelect", scImageSelect);
		app.component("scImageUpload", scImageUpload);
		app.component("scUrlSelect", scUrlSelect);
		app.component("scGoodSelect", scGoodSelect);
		app.component("scVideoUpload", scVideoUpload);

		//注册全局指令
		app.directive("auth", auth);
		app.directive("auths", auths);
		app.directive("auths-all", authsAll);
		app.directive("role", role);
		app.directive("time", time);
		app.directive("copy", copy);
		app.directive("has", has);

		//统一注册el-icon图标
		for (let icon in elIcons) {
			app.component(`ElIcon${icon}`, elIcons[icon]);
		}
		//统一注册sc-icon图标
		for (let icon in scIcons.default) {
			app.component(`ScIcon${icon}`, scIcons.default[icon]);
		}

		//统一注册el-icon图标
		for (let icon in i4Icons) {
			app.component(`I4Icon${icon}`, i4Icons[icon]);
		}
		for (let icon in i5Icons) {
			app.component(`I5Icon${icon}`, i5Icons[icon]);
		}

		//关闭async-validator全局控制台警告
		window.ASYNC_VALIDATOR_NO_WARNING = 1;

		//全局代码错误捕捉
		app.config.errorHandler = errorHandler;
	},
};
