import config from "@/config";
import http from "@/utils/request";

export default {
	goods: {
		list: {
			url: `${config.API_URL}/goods/list`,
			name: "获取商品列表",
			get: async function (data = {}, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/goods/store`,
			name: "新增编辑商品",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/goods/getDetail`,
			name: "获取商品详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		updataStatus: {
			url: `${config.API_URL}/goods/enable`,
			name: "修改商品状态",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/goods/del`,
			name: "删除商品",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	brand: {
		list: {
			url: `${config.API_URL}/brand/list`,
			name: "获取品牌列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/brand/store`,
			name: "新增编辑品牌",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/brand/delete`,
			name: "删除品牌",
			delete: async function (data = {}) {
				return await http.delete(this.url, data);
			},
		},
		all: {
			url: `${config.API_URL}/brand/all`,
			name: "获取全部品牌列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	classify: {
		list: {
			url: `${config.API_URL}/category/list`,
			name: "获取分类列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/category/store`,
			name: "新增编辑分类",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/category/del`,
			name: "删除分类",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		all: {
			url: `${config.API_URL}/category/all`,
			name: "获取全部分类列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	scene: {
		list: {
			url: `${config.API_URL}/goods_scene/index`,
			name: "获取商品场景列表",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/goods_scene/store`,
			name: "新增编辑商品场景",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/goods_scene/show`,
			name: "获取商品场景详情",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		updataStatus: {
			url: `${config.API_URL}/goods_scene/enable`,
			name: "启用禁用商品场景",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/goods_scene/del`,
			name: "删除商品场景",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	guarantee: {
		list: {
			url: `${config.API_URL}/goods_service/index`,
			name: "获取保障服务列表",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/goods_service/store`,
			name: "新增编辑保障服务",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/goods_service/show`,
			name: "获取保障服务详情",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		updataStatus: {
			url: `${config.API_URL}/goods_service/enable`,
			name: "启用禁用保障服务",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/goods_service/del`,
			name: "删除保障服务",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
	},
	freight: {
		list: {
			url: `${config.API_URL}/config/areaList`,
			name: "获取运费模板列表",
			get: async function (data = {}, config = {}) {
				return await http.get(this.url, data, config);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/config/editArea`,
			name: "新增编辑运费模板",
			post: async function (data = {}, config = {}) {
				return await http.post(this.url, data, config);
			},
		},
	},
	pictorial: {
		list: {
			url: `${config.API_URL}/picture_card/list`,
			name: "获取图鉴列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/picture_card/store`,
			name: "新增编辑图鉴",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/picture_card/detail`,
			name: "获取图鉴详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/picture_card/del`,
			name: "删除图鉴",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
};
