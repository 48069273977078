<template>
	<el-dialog append-to-body title="上传图片" v-model="visible" align-center :width="1100" destroy-on-close
		@closed="$emit('closed')">
		<sc-file-select v-model="file" :multiple="multiple" :hideUpload="hideUpload" :max="max" @submit="submit" />
	</el-dialog>
</template>

<script>
	import scFileSelect from '@/components/scFileSelect'
	export default {
		emits: ['success', 'closed'],
		components: {
			scFileSelect
		},
		props: {
			max: { type: Number, default: 9 },
			multiple: { type: Boolean, default: false },
			hideUpload: { type: Boolean, default: false }
		},
		data() {
			return {
				visible: false,
				file: '',
			}
		},
		mounted() {
		},
		methods: {
			//显示
			open() {
				this.visible = true;
				return this
			},
			//表单提交方法
			submit(value) {
				this.$emit("success", value)
				this.visible = false
			}
		}
	}
</script>

<style></style>
